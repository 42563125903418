


export const Header = (props) => {
  return (
    <header id='header' >
      <div className='intro'>
        <div className='overlay'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-8 col-md-offset-2 intro-text '>

                
                  
                 <h1><img className='transparent-bg bg-logo' 
                 src= "https://drive.google.com/uc?export=view&id=1FxyYU7OV1ov4K9N1BIZyFDhSW-nL_jMo"></img></h1>
                
                {/* <p className='transparent-bg'>{props.data ? props.data.paragraph : 'Loading'}</p> */}
                <button type='submit'   className='btn btn-custom  page-scroll btn-lg'>
                  <a style={{color:"inherit"}} className="page-scroll" href="#contact">Get in touch with our Designer </a>
                </button>
                
                <section id="section03" className="demo  ">
                 
                  <a className="page-scroll" data-bs-spy="scroll" href="#features"><span></span>Learn More</a>
                </section>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
